.main-device-section {
    padding: 72px 0;
    overflow: hidden;
}

.main-device-section .devices-slider-container {
    margin-bottom: 60px;
}

.devices-slider-container .slider-navigation {
    top: -172px;
    width: 32px;
    height: 32px;
}

.devices-slider-container .slider-navigation.nav:first-of-type {
    right: 56px;
    left: unset;
}

.devices-slider-container .slider-navigation.nav:last-of-type {
    right: 0;
    transform: rotate(180deg);
}

.main-device-section .section-title {
    text-align: center;
}

.main-device-section .section-description {
    margin: 36px auto;
    text-align: center;
    max-width: 656px;
}

.devices-section-title-block {
    display: flex;
    flex-direction: column;
}

.cosmetology-device-card {
    width: 405px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.main-device-section .cosmetology-device-card:first-child {
    margin-left: 10px;
}

.cosmetology-device-card:hover .cosmetology-device-card-img {
    box-shadow: 0 4px 14px 0 #0000001C;
}

.cosmetology-device-card .cosmetology-device-card-img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
}

.cosmetology-device-card .cosmetology-device-card-caption {
    color: rgba(40, 40, 40, 0.70);
    font-size: 14px;
    line-height: 24px;
}

.cosmetology-device-card .cosmetology-device-card-title {
    color: var(--dark-grey);
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
}

.all-cosmetology-devices {
    display: flex;
    flex-direction: column;
    padding: 0 0 66px;
}

.all-cosmetology-devices .cosmetology-devices-list {
    display: flex;
    flex-wrap: wrap;
}

.all-cosmetology-devices .cosmetology-devices-list .cosmetology-device-card {
    width: calc((100% - 2 * 32px) / 3);
    margin-right: 32px;
    margin-bottom: 48px;
}

.all-cosmetology-devices .cosmetology-devices-list .cosmetology-device-card-img {
    background: linear-gradient(0deg, #E5E5E5 0%, #E5E5E5 100%), lightgray 50% / cover no-repeat;
}

.all-cosmetology-devices .cosmetology-devices-list .cosmetology-device-card:nth-child(3n) {
    margin-right: 0;
}

.all-cosmetology-devices .section-description {
    margin: 36px auto;
    text-align: center;
    max-width: 888px;
}

@media screen and (max-width: 1320px) {
}

@media screen and (max-width: 991px) {
    .main-device-section {
        padding: 48px 0;
    }

    .main-device-section .section-title {
        text-align: left;
    }

    .main-device-section .section-description {
        text-align: left;
        margin: 12px 0;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
    }
    .cosmetology-device-card {
        width: auto;
    }
    .all-cosmetology-devices .cosmetology-devices-list .cosmetology-device-card {
        width: calc((100% - 32px) / 2);
        margin-right: 30px;
        margin-bottom: 30px;
    }

    .all-cosmetology-devices .cosmetology-devices-list .cosmetology-device-card:nth-child(3n) {
        margin-right: 32px;
    }

    .all-cosmetology-devices .cosmetology-devices-list .cosmetology-device-card:nth-child(2n) {
        margin-right: 0;
    }
}

@media screen and (max-width: 768px) {

    .main-device-section .devices-slider-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
    }

    .main-device-section .cosmetology-device-card:first-child {
        margin-left: 0;
    }
    .all-cosmetology-devices .section-description {
        margin: 32px auto;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
}

@media screen and (max-width: 600px) {
    .all-cosmetology-devices .cosmetology-devices-list {
        justify-content: center;
    }

    .all-cosmetology-devices .cosmetology-devices-list .cosmetology-device-card {
        width: 100%;
        margin-right: 0 !important;
        max-width: 400px;
    }

}