.cosmetology-device-item-page {
    display: flex;
    justify-content: center;
    background: var(--cream);
}
.device-read-more{
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    text-transform: uppercase;
    color: #000000;
}

.device-read-more::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-image: url("../../assets/devices/read-more.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 5px;
    vertical-align: middle;
}

.cosmetology-left-column {

    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.cosmetology-left-column img {
    max-width: 100%;
    height: auto;
    border-left: solid 1px rgba(0, 0, 0, 0.08);
}

.cosmetology-right-column {
    flex: 1;
    max-width: 592px;
    margin: 0 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

.cosmetology-right-column .cosmetology--item-title {
    color: #000000;
    font-family: Lustria;
    text-transform: uppercase;
    font-size: 42px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-bottom: 30px;
}

.cosmetology-right-column .cosmetology--item-des {
    color: var(--dark-grey);
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    margin-bottom: 10px;
}

.device-item-page-btn {
    margin: 32px 0 63px 0;
}

@media screen and (max-width: 991px) {

    .cosmetology-device-item-page {
        display: flex;
        flex-direction: column;
    }

    .cosmetology-left-column {
        flex-direction: column;
    }
    .cosmetology-left-column img {
        max-width: 100%;
        height: auto;
        width: 375px;
        padding: 29px 0 40px 0;
        border: none;
    }
    .cosmetology--item-des{
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        padding-top: 36px;
    }
    .cosmetology--item-title{
        font-family: Lustria;
        font-size: 35px;
        font-weight: 400;
        line-height: 46px;
        text-align: center;
        color: var(--dark-grey);
    }

    .cosmetology-right-column {
        background: var(--white);
        margin: auto;
        width: 100%;
        max-width: 100%;
    }
    .cosmetology-right-column .cosmetology--item-des {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        padding: 33px 16px 0;
    }
}