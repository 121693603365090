
.about-us-page-content {
    padding: 110px 0 0;
}

.about-us-page-content .about-us-top-block {
    display: flex;
    margin-bottom: 154px;
    justify-content: space-between;
}

.about-us-page-content .about-us-top-block .about-us-details {
    width: 100%;
    margin: 0 124px 0 0;
}

.right-text {
    margin: 0 0 0 124px !important;
}

.about-us-details h2 {
    font-family: "Lustria";
    color: var(--dark-grey);
    font-size: 42px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-bottom: 25px;
}

.about-us-text-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}


.about-us-details p {
    color: var(--dark-grey);
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    /*margin-bottom: 12px;*/
}

.about-us-page-content .book-visit-btn {
    margin-top: 32px;
}

.section-about-page{
    background: var(--cream);
    margin-top: 58px;
}

.distributor-list-grid{
    display: flex;
    padding-top: 120px;
    gap: 20px;
    flex-wrap: wrap;
}

.distributor-list-grid-item{
    height: 266px;
    width: 100%;
    max-width: 294px;
    background-color: #FFFFFF;
    object-fit: contain;
    overflow: auto;
}
.distributor-list-grid-item img{
    padding-top: 54px;
}

.distributor-list-grid-item h6{
    max-width: 240px;
    font-family: Lato;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #282828;
    margin: 10px auto 0;
}


.about-background-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    min-height: 639px;
    background-image: url('../../assets/about-us/about-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 120px 0 0 0;
}

.about-img-title {
    font-family: Lato;
    font-size: 102px;
    font-weight: 400;
    line-height: 122.4px;
    letter-spacing: -0.6000000238418579px;
    text-align: center;
    color: var(--white);
    margin-bottom: 38px;
}
.distributor-title{
    color: var(--dark-grey);
    font-size: 42px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.5px;
    text-align: center;
    padding: 135px 0 39px;
}

.club-list-grid{
    display: flex;
    justify-content: space-around;
    padding-bottom: 104px;
}

.club-list-card{
    width: 100%;
    max-width: 507px;
    background-color: #FFFFFF;
    padding-top: 54px;
    padding-bottom: 42px;
}
.club-list-card h3{
    font-family: Lora;
    font-weight: 500;
    font-size: 30px;
    line-height: 46px;
    letter-spacing: -0.6px;
    text-align: center;
    color: #282828;
}
.club-list-card-address{
    margin: 27px 0;
}
.club-list-card p{
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    color: #282828;
}
.club-list-card a{
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    line-height: 13.96px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(40, 40, 40, 0.7);
}


.club-list-grid-item{
    width: 100%;
    max-width: 507px;
}

@media screen and (max-width: 1320px) {

}

@media screen and (max-width: 991px) {

    .about-us-page-content .about-us-top-block {
        display: flex;
        margin-bottom: 48px;
        justify-content: space-between;
    }
    .about-us-page-content .about-us-top-block:last-child {
         margin-bottom: 27px;
     }

    .about-us-page-content .about-us-top-block .about-us-details {
        width: 100%;
        margin: auto;
    }


    .about-us-page-content {
        padding: 20px 0 0;
    }
    .about-us-details h2 {
        font-size: 35px;
        font-weight: 400;
        line-height: 46px;
        margin: 16px 0 8px 0;
    }
    .about-us-details p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin: 0;
    }
    .about-img-title{
        font-size: 35px;
        font-weight: 400;
        line-height: 46px;
        text-align: center;
    }
    .about-us-page-content .brands-list-wrapper {
        padding: 27px 0 11px;
    }

    .about-us-page-content .brands-list-grid .brands-list-grid-item {
        width: calc((100% - 3 * 16px) / 4);
        margin-bottom: 16px;
    }

    .about-us-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(6n)) {
        margin-right: 0;
    }

    .about-us-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(4n)) {
        margin-right: 16px;
    }

    .about-us-page-content .book-visit-btn {
        margin-top: 0;
    }
}

@media screen and (max-width: 600px) {

    .about-us-page-content .about-us-top-block {
        flex-direction: column;
    }

    .about-us-page-content .brands-top-img {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .about-background-img {
        background-image: url('../../assets/about-us/about-background-mob.jpg');
        margin: 0;
        min-height: 375px;
    }
    .distributor-title{
        font-family: Lustria;
        font-size: 35px;
        font-weight: 400;
        line-height: 46px;
        text-align: left;
        padding: 48px 0 25px 0;
    }
    .club-list-grid {
        flex-direction: column;
        padding-bottom: 25px;
    }
    .club-list-grid > div:first-child{
        margin-bottom: 25px;
    }
    .about-us-page-content .brands-list-grid .brands-list-grid-item {
        width: calc((100% - 2 * 16px) / 3);
    }

    .about-us-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(4n)) {
        margin-right: 0;
    }
}