.hidden {
    display: none;
}

/* page */
.page-container {
    width: 1280px;
    margin: 0 auto;
}

.full-width-content {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

/* section */
.section-title {
    font-family: Lustria, sans-serif;
    color: var(--dark-grey);
    font-size: 42px;
    font-weight: 500;
    cursor: pointer;
}

.section-description {
    color: rgba(40, 40, 40, 0.70);
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
}

.section-delimiter {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.20);
}

.section-title:hover {
    text-decoration-line: underline;
}

/* banner */
.page-banner {
    display: flex;
    align-content: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 324px;
    flex-wrap: wrap;

    color: var(--white);
    font-family: Lustria, sans-serif;
    font-size: 42px;
    line-height: 46px;
    font-weight: 400;
}

.theme-button {
    height: 56px;
    width: 302px;
    padding: 0 48px;
    cursor: pointer;

    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.theme-button-xs {
    height: 44px;
    width: fit-content;

}

/* primary button */
.primary-button {
    background-color: var(--dark-grey);
    border: 1px solid var(--dark-grey);
    color: var(--white);
}

.primary-button:hover {
    border: 1px solid #444444;
    background-color: #444444;
}

.primary-button:active {
    border: 1px solid var(--dark);
    background-color: var(--dark);
}

/* accent button */
.accent-button {
    background-color: var(--white);
    border: 1px solid var(--white);
    color: var(--dark-grey);
}

.accent-button:hover {
    border: 1px solid #E5E5E5;
    background-color: #E5E5E5;
    color: var(--dark-grey);
}

.accent-button:active {
    border: 1px solid #D5D5D5;
    background-color: #D5D5D5;
}

@media screen and (max-width: 1320px) {
    /* page */
    .page-container {
        width: 100%;
        padding-right: 40px;
        padding-left: 40px;
    }
}

@media screen and (max-width: 991px) {
    /* section */
    .section-title {
        text-align: left;
        font-size: 35px;
        line-height: 46px;
        margin-bottom: 8px;
    }
    .section-delimiter{
        display: none;
    }

    /* action button */
    .primary-button {
        height: 56px;
        font-size: 16px;
        font-weight: 400;
    }

}

@media screen and (max-width: 600px) {
    .page-container {
        padding-right: 15px;
        padding-left: 15px;
    }

    .primary-button {
        width: 100%;
        max-width: 374px;
    }

    /* banner */
    .page-banner {
        height: 234px;
        font-size: 35px;
        font-weight: 400;
        line-height: 46px;
        text-align: center;
    }
}