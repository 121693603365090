@import "components/theme.css";

:root {
    --white: #FFFFFF;
    --black: #24221f;
    --dark-blue: #233657;
    --yellow: #EAD295;
    --blue: #B8DFEC;
    --dealer: #1D1E21;
    --grey: #717d7c;
    --light-blue: #ECF6F9;
    --light-yellow: #F9F1DF;
    --light-green: #EEF9EC;
    --extra-green: #092927;

    --light-grey: #696969;
    --dark-grey: #282828;
    --dark: #131313;
    --cream: #F7F7F7;
    --border: rgba(0, 0, 0, 0.20);
    --header-height-desktop: 126px;
    --header-height-mobile: 76px;
}

html {
    width: 100%;
    max-width: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: Lato, Lustria, sans-serif;
    margin: 0;
    min-height: 100vh;
    max-width: 100vw;
    width: 100%;
    overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    cursor: pointer;
}
a > p:hover {
    cursor: pointer;
    text-decoration: underline;
}
a > span:hover {
    cursor: pointer;
    text-decoration: underline;
}

button {
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

button, button a {
    color: var(--dealer);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
}

button:hover {
    background-color: var(--dark-blue);
    color: var(--white);
    border: 1.5px solid transparent;
}

button:hover, button:hover a {
    color: var(--white);
    border: none;
}

button:active {
    background-color: var(--cream);
    color: var(--white);
    border: 1.5px solid transparent;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.icon-button, .icon-button:hover {
    outline: none;
    border: none;
    background: none;
    padding: 0;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-start {
    align-items: flex-start;
}

.space-between {
    justify-content: space-between;
}

#root {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
    top: 0;
    left: 0;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: var(--dark-blue) transparent var(--dark-blue) transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

.no-data-found {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    color: var(--black);
    padding-top: 40px;
}


/*ticker*/
.service-ticker {
    height: 112px;
    background-color: var(--dark-blue);
    display: flex;
}




@keyframes spin-anim {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}