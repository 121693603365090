.main-instagram-section {
    padding-top: 72px;
    padding-bottom: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-instagram-section .section-title {
    font-family: Montserrat;
    text-align: center;
    margin-bottom: 12px;
}

.main-instagram-section .section-description {
    text-align: center;
    max-width: 530px;
    margin-bottom: 32px;
}
.main-instagram-section .post-grid {
    display: flex;
    flex-wrap: wrap;
}
.main-instagram-section .post-grid img {
    object-fit: cover;
    width: calc((100% - 3 * 5px) / 4);
    height: 100%;
    margin-bottom: 5px;
    cursor: pointer;
}

.main-instagram-section .post-grid img:not(:last-child) {
    margin-right: 5px;
}

@media screen and (max-width: 1320px) {

}

@media screen and (max-width: 991px) {
    .main-instagram-section .post-grid img {
        width: calc((100% - 2 * 5px) / 3);
    }
    .main-instagram-section .post-grid img:nth-child(3n) {
        margin-right: 0;
    }

    .main-instagram-section {
        padding-top: 32px;
        padding-bottom: 32px;
        align-items: flex-start;
    }
}