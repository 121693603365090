.book-button-wrapper{
    height: 0;
    position: relative;
}
.book-button {
    width: 137px;
    height: 137px;
    border-radius: 50%;
    background-color: var(--dark-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 27px;
    color: var(--white);
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 9999;
    cursor: pointer;
    font-weight: 400;
    line-height: 32px;
}
.book-button-fixed{
    position: absolute;
    right: 40px;
    display: flex;
}
.book-button:hover{
    border: 2px solid var(--light-grey);
    background-color: var(--dark-grey);
    color: var(--white);
}
.book-button:active{
    background-color: var(--dark);
    border: none;
}

/*MOBILE*/
@media screen and (max-width: 991px) {
    .book-button{
        width: 84px;
        height: 84px;
        border: 3px solid rgba(255, 255, 255, 0.2);
        font-size: 16px;
        line-height: 20px;
        bottom: 14px;
        right: 14px;
    }
}