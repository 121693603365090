.main-team-section {
    padding: 72px 0;
}
.page-team-section {
    padding: 44px 0;
}
.team-wrapper{
    display: flex;
}
.team-des-offered{
    padding: 64px 0;
}

.main-team-section .team-section-title-block,
.page-team-section .team-section-title-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main-team-section .team-section-title-block .section-title {
    text-align: center;
}
.main-team-section .team-section-title-block .section-description,
.page-team-section .team-section-title-block .section-description {
    text-align: center;
    max-width: 880px;
    margin: 0 0 24px 0;
}

.team-left-column {
    background: var(--cream);
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.team-left-column .team-item-title{
    font-family: Lustria;
    font-size: 42px;
    font-weight: 400;
    line-height: 46px;
    text-align: left;
}
.team-left-column .service-item-des{
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    max-width: 568px;
    color: var(--dark-grey);
    font-family: Lato Light;
}

.team-item-des{
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: var(--dark-grey);
    opacity: 0.7;
}
.team-languages{
    margin-top: 62px;
}
.skills-cont h5{
    display: inline-block;
    vertical-align: middle;
}
.skills-cont h5::before{
    content: url("../../assets/team/Frame_1000002571.svg");
    margin-right: 10px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
}
.skills-cont p{
    padding-left: 25px;
}
.skills-cont{
    position: relative;
}
.skills-description{
    margin-bottom: 0;
}
.team-container {
    display: flex;
    margin-bottom: 48px;
    flex-wrap: wrap;
    justify-content: center;
}

.team-member-card {
    width: calc((100% - 3 * 32px) / 4);
    margin: 0 24px 25px 0;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
}
.team-member-card .team-member-card-img {
    height: 302px;
}
.team-member-card .team-card-img {
    height: 380px;
}
.team-member-card:hover .team-member-card-img {
    box-shadow: 0 4px 14px 0 #0000001C;
}

.team-member-card .team-member-card-name {
    color: var(--dark-grey);
    font-size: 18px;
    line-height: 28px;
    margin-top: 24px;
}

.team-member-card .team-member-card-specialty {
    color: rgba(40, 40, 40, 0.70);
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
}


.team-member-card:last-child {
    margin-right: 0;
}

.team-member-card-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.team-card-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.team-list-grid{
    display: flex;
    justify-content: center;
    padding: 0 0 122px 0;
}
.team-list-grid-item{
    max-width: 587px;
}
.team-list-grid-item h5{
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
}
.team-list-grid-item p{
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    opacity: 0.7;
    margin-bottom: 12px;
}


@media screen and (max-width: 991px) {
    .main-team-section {
        padding: 48px 0;
    }
    .page-team-section{
        padding: 20px 0;
    }
    .team-wrapper{
        flex-direction: column-reverse;
    }
    .team-container {
        flex-wrap: wrap;
        justify-content: center;
    }
    .team-left-column{
        background: none;
        padding-top: 44px;
    }
    .team-left-column .team-item-title{
        font-family: Lustria;
        font-size: 35px;
        font-weight: 400;
        line-height: 46px;
        text-align: center;
    }
    .team-item-des{
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: rgba(40, 40, 40, 0.7);
    }
    .team-left-column .service-item-des{
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
    }
    .team-list-grid-item h5{
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        text-align: left;
        margin-bottom: 12px;
    }
    .team-list-grid-item p{
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: rgba(40, 40, 40, 0.7);
    }
    .team-languages{
        margin-top: 17px;
    }
    .team-skill{
        margin-top: 17px;
    }

    .team-member-card {
        width: calc((100% - 32px) / 2);
        margin-bottom: 32px;
        max-width: 340px;
    }
    .team-member-card .team-member-card-img {
        height: 348px;
    }
    .team-member-card .team-card-img {
        height: 343px;
    }
    .team-des-offered{
        padding: 44px 0;
    }

    .team-member-card:nth-child(2n) {
        margin-right: 0;
    }
    .team-list-grid{
        padding: 0 0 67px 0;
    }
}

@media screen and (max-width: 600px) {
    .main-team-section .team-section-title-block .section-title {
        text-align: left;
        margin-bottom: 16px;
    }
    .main-team-section .team-section-title-block {
        align-items: flex-start;
    }

    .team-member-card {
        width: 100%;
        margin-right: 0;
        max-width: 348px;
        justify-content: flex-start;
    }
    .team-member-card .team-member-card-name {
        margin-top: 12px;
    }
    .team-container {
        margin-bottom: 0;
    }
    .team-list-grid{
        flex-direction: column;
    }
}