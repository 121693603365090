/*404*/
.page-not-found .title {
    font-weight: 300;
    font-size: 25px;
    line-height: 17px;
    text-align: center;
    color: var(--dark-grey);
    margin-bottom: 27px;
    text-transform: uppercase;
}
.page-not-found p {
    font-weight: 300;
    font-size: 19px;
    line-height: 12px;
    text-align: center;
    color: var(--dark-grey);
    margin-top: 35px;
    text-transform: uppercase;
}
.page-not-found {
    min-height: 463px;
    height: 100%;
    background-image: url('../../assets/not-found.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
}

/*MOBILE*/
@media screen and (max-width: 991px) {
    .page-not-found .title {
        font-weight: 300;
        font-size: 20px;
        line-height: 14px;
        text-align: center;
        color: var(--dark-grey);
        margin-bottom: 22px;
        text-transform: uppercase;
    }
    .page-not-found {
        min-height: 416px;
        height: 100%;
        background-image: url('../../assets/not-found.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .page-not-found img{
        margin: 0 33px;
        max-width: 309px;
    }
    .page-not-found p {
        font-weight: 300;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        color: var(--dark-grey);
        margin-top: 28px;
        text-transform: uppercase;
    }
}