.main-reviews-section {
    padding: 72px 0 42px;
    background: var(--cream);
}
.reviews-info-block{
    display: flex;
    justify-content: space-between;
    padding-right: 60px;
}
.main-reviews-section .section-title {
    text-align: left;
}

.main-reviews-section .section-title:hover {
    text-decoration: none;
    cursor: auto;
}

.main-reviews-section .review-item {
    object-fit: cover;
    width: 345px;
    height: 500px;
    background-color: #FFFFFF;
    gap: 20px;
    padding: 33px 24px;
}
.review-item-top{
    display: flex;
    gap: 15px;
    margin-bottom: 24px;
}


.review-item-name{
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #282828;
}
.review-item-date{
    font-family: Lato;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(40, 40, 40, 0.5);
}
.review-item-title{
    font-family: Lato;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #282828;
}
.review-item-description{
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #282828;
}



.review-icon{
    display: flex;
    padding-right: 50px;
}

.main-reviews-section .estimation-block {
    display: flex;
    align-items: center;
    /*position: absolute;*/
    top: 90px;
}

.main-reviews-section .estimation-block span {
    margin-right: 8px;
}

.reviews-slider-container {
    position: relative;
    max-width: 1440px;
    margin: 66px auto 0;
}

.reviews-slider-container .slider-navigation {
    top: -112px;
    width: 32px;
    height: 32px;
}

.reviews-slider-container .slider-navigation.nav:first-of-type {
    right: calc(50% - (1280px / 2 - 20px));
    transform: translate(-50%);
    left: unset;
}

.reviews-slider-container .slider-navigation.nav:last-of-type {
    right: calc(50% - (1280px / 2 - 10px));
    transform: rotate(180deg) translate(-50%);
}

@media screen and (max-width: 1320px) {
    .reviews-slider-container .slider-navigation {
        top: -106px;
    }

    .reviews-slider-container .slider-navigation.nav:first-of-type {
        right: 96px;
        transform: unset;
    }

    .reviews-slider-container .slider-navigation.nav:last-of-type {
        right: 40px;
        transform: rotate(180deg);
    }
}

@media screen and (max-width: 991px) {
    .main-reviews-section{
        padding: 32px 0 42px;
    }
    .reviews-slider-container {
        margin-top: 24px;
    }
    .main-reviews-section .estimation-block {
        position: initial;
        top: unset;
        margin-top: 20px;
    }
    .main-reviews-section .reviews-info-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .review-icon{
        flex-direction: column;
        padding-right: 0;
    }

    .reviews-slider-container .slider-navigation {
        top: -54px;
    }
}

@media screen and (max-width: 600px) {
    .main-reviews-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 0 24px;
    }

    .main-reviews-section .reviews-slider-container {
        width: 370px;
        max-width: 370px;
        padding-bottom: 56px;
    }
    .main-reviews-section .reviews-info-block {
        align-items: flex-start;
    }


    .main-reviews-section .section-title {
        text-align: left;
    }


    .main-reviews-section .review-item {
        padding: 0 0 0 16px;
    }


    .review-item-title{
        font-size: 18px;
    }
    .review-item-description{
        font-size: 16px;
    }

    .main-reviews-section .slider-navigation {
        top: unset;
        bottom: -50px;
        width: 32px;
        height: 32px;
    }

    .main-reviews-section .slider-navigation.nav:first-of-type {
        left: calc(50% - 64px);
        transform: translateX(-50%);
    }

    .main-reviews-section .slider-navigation.nav:last-of-type {
        right: calc(50% - 64px);
        transform: rotate(180deg) translateX(-50%);
    }

}
