.privacy-page {
    color: var(--dark-grey);
}

.privacy-page a {
    text-decoration: none;
    cursor: pointer;
    color: var(--dark-grey);
}

.privacy-title {
    margin: 57px 0 34px 0;
    font-family: Lustria;
    font-size: 42px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.5px;
    text-align: center;
}

.privacy-page.opacity07 {
    opacity: 0.7;
}

.privacy-page.description {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
}

.privacy-page.bold {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
}

@media screen and (max-width: 991px) {
    .privacy-page .privacy-title {
        margin: 37px 0 16px 0;
        font-size: 35px;
    }

    .privacy-page h4, .privacy-page p {
        font-size: 14px;
        line-height: 24px;
    }
    .privacy-page .privacy-span-mobile {
        line-height: 16px;
    }
}
