.main-brands-section {
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    background: var(--cream);
    padding: 102px 0 110px;
}

.main-brands-section .brands {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-brands-section .brands > img{
    max-width: 572px;
}

.main-brands-section .brands * {
    width: calc((100% - 46px) / 2);
}

.main-brands-section .brands-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.main-brands-section .brands-description {
    color: var(--dark-grey);
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    width: 100%;
    margin-bottom: 8px !important;
}
.brands-description-read-btn{
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-transform: none;
}

.main-brands-section .brands-info * {
    margin-bottom: 24px;
}

.main-brands-section .brands-logos {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
}

.main-brands-section .brands-logos .logo {
    margin-right: 54px;
}

.main-brands-section .brands-logos .logo:nth-child(1) {
    width: 116px;
    height: 28px;
    background: url("../../assets/brands/brands-logo-1.svg") no-repeat;
}

.main-brands-section .brands-logos .logo:nth-child(2) {
    width: 129px;
    height: 42px;
    background: url("../../assets/brands/brands-logo-2.svg") no-repeat;
}

.main-brands-section .brands-logos .logo:nth-child(3) {
    width: 124px;
    height: 65px;
    background: url("../../assets/brands/brands-logo-3.svg") no-repeat;
}

.main-brands-section .brands-logos .logo:last-child {
    margin-right: 0;
}

.brands-page-content {
    padding: 48px 0 63px;
}

.brands-page-content .brands-top-img-block {
    display: flex;
    margin-bottom: 48px;
}

.brands-page-content .brands-top-img {
    height: 100%;
    width: calc((100% - 2 * 32px) / 3);
    object-fit: contain;
}

.brands-page-content .brands-top-img:not(:last-child) {
    margin-right: 32px;
}
.brands-page-content .brands-list-wrapper {
    padding: 72px 0 18px;
    background-color: var(--cream);
}
.brands-page-content .brands-list-grid {
    display: flex;
    flex-wrap: wrap;
}

.brands-page-content .brands-list-grid .brands-list-grid-item {
    height: 100%;
    width: calc((100% - 5 * 32px) / 6);
    object-fit: contain;
    margin-bottom: 54px;
}
.brands-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(6n)) {
    margin-right: 32px;
}

.brands-page-content .book-visit-btn {
    margin-top: 32px;
}

@media screen and (max-width: 1320px) {

}

@media screen and (max-width: 991px) {
    .main-brands-section {
        padding: 32px 0;
    }

    .main-brands-section .brands {
        flex-direction: column;
    }

    .main-brands-section .brands * {
        width: 100%;
    }

    .main-brands-section .read-more-btn {
        margin-top: 12px;
        width: 302px;
    }

    .brands-page-content {
        padding: 20px 0 27px;
    }
    .brands-page-content .brands-list-wrapper {
        padding: 27px 0 11px;
    }
    .brands-page-content .brands-list-grid .brands-list-grid-item {
        width: calc((100% - 3 * 6px) / 4);
        margin-bottom: 6px;
    }
    .brands-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(6n)) {
        margin-right: 0;
    }
    .brands-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(4n)) {
        margin-right: 6px;
    }
    .brands-page-content .book-visit-btn {
        margin-top: 27px;
    }
}

@media screen and (max-width: 600px) {
    .main-brands-section .brands-logos {
        display: none;
    }

    .main-brands-section .read-more-btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .brands-page-content .brands-top-img-block {
        flex-direction: column;
        margin-bottom: 0;
    }
    .brands-page-content .brands-top-img {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .brands-page-content .brands-list-grid .brands-list-grid-item {
        width: calc((100% - 2 * 6px) / 3);
    }
    .brands-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(4n)) {
        margin-right: 0;
    }
    .brands-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(3n)) {
        margin-right: 6px;
    }
}