.main-about-us-section {
    margin-bottom: 60px;
}
.main-page-title{
    font-family: Lustria;
    font-weight: 400;
    font-size: 42px;
    line-height: 46px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #282828;
    padding-bottom: 34px;
}

.main-about-us-section .about-us-wrapper {
    display: flex;
    background: #233657;
    height: 554px;
}

.main-about-us-section .about-us-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(100% - 734px);
    padding: 55px;
}

.main-about-us-section .about-us-info .section-description {
    color: white;
    margin: auto;
}

.main-about-us-section .about-us-info .read-more-btn {
    /*position: absolute;*/
   margin-top: 30px;
    bottom: 55px;
    left: 55px;
}

.main-about-us-section .about-us-video-container {
    width: 734px;
    height: 554px;
}

.main-about-us-section .about-us-video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1320px) {
    .main-about-us-section .about-us-wrapper {
        height: auto;
    }

    .main-about-us-section .about-us-video-container {
        height: auto;
        width: 500px;

    }

    .main-about-us-section .about-us-info {
        align-items: flex-start;
        width: calc(100% - 500px);
    }
}

@media screen and (max-width: 991px) {
    .main-about-us-section {
        margin-top: 15px;
        margin-bottom: 35px;
    }
    .main-page-title{
        font-size: 23px;
        line-height: 32px;
        letter-spacing: 0.4px;
        text-align: center;
        padding-bottom: 19px;
    }
    .main-about-us-section .about-us-wrapper {
        flex-direction: column-reverse;
        height: auto;
    }

    .main-about-us-section .about-us-video-container {
        width: 100%;
        height: auto;
    }

    .main-about-us-section .about-us-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 32px 24px;

    }
    .main-about-us-section .about-us-info .section-description {
        font-size: 14px;
    }

    .main-about-us-section .about-us-info .read-more-btn {
        position: initial;
        margin-top: 16px;
    }
    .about-us-slide-mob{
        height: 322px;
        margin-bottom: 16px;
    }
    .about-us-slide-mob .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 16px;
    }
    .about-us-slide-mob img{
        margin: 0 auto;
    }
}