@import url('https://fonts.googleapis.com/css2?family=Lustria&display=swap');


.contacts-page-content {
    padding: 48px 0 63px;
}

.contacts-page-content .contacts-top-img-block {
    display: flex;
    margin-bottom: 48px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.11);
}

.contacts-page-content .contacts-top-img-block .address-details {
    width: 100%;
    margin: 0 32px 0 80px;
}

.address-details h2 {
    font-family: "Lustria";
    color: var(--dark-grey);
    font-size: 42px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-bottom: 25px;
}

.icon-text-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.icon {
    margin-right: 9px;
    width: 18px;
    height: 18px;
}

.address-details p {
    color: var(--dark-grey);
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    /*margin-bottom: 12px;*/
}

.contacts-page-content .book-visit-btn {
    margin-top: 32px;
}

@media screen and (max-width: 1320px) {

}

@media screen and (max-width: 991px) {
    .contacts-page-content .contacts-top-img-block .address-details {
        width: 100%;
        margin: auto;
    }
    .main-brands-section {
        padding: 32px 0;
    }

    .main-brands-section .brands {
        flex-direction: column;
    }

    .main-brands-section .brands * {
        width: 100%;
    }

    .main-brands-section .read-more-btn {
        margin-top: 12px;
        width: 302px;
    }

    .contacts-page-content {
        padding: 20px 0 27px;
        margin: 0 5px;
    }

    .contacts-page-content .brands-list-wrapper {
        padding: 27px 0 11px;
    }

    .contacts-page-content .brands-list-grid .brands-list-grid-item {
        width: calc((100% - 3 * 16px) / 4);
        margin-bottom: 16px;
    }

    .contacts-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(6n)) {
        margin-right: 0;
    }

    .contacts-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(4n)) {
        margin-right: 16px;
    }

    .contacts-page-content .book-visit-btn {
        margin-top: 27px;
    }
}

@media screen and (max-width: 600px) {

     .contacts-top-img-block .img-address{
        width: 100%;
    }

    .address-details h2{
        font-size: 35px;
        font-weight: 400;
        line-height: 46px;
    }

    .main-brands-section .brands-logos {
        display: none;
    }
    .contacts-page-content .contacts-top-img-block .address-details{
        padding: 31px 18px;
    }
    .address-details p{
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
    }

    .main-brands-section .read-more-btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .contacts-page-content .contacts-top-img-block {
        flex-direction: column;
        margin-bottom: 20px;
    }
    .contacts-page-content .contacts-top-img-block:first-child {
        flex-direction: column-reverse;
        margin-bottom: 30px;
    }

    .contacts-page-content .brands-top-img {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .contacts-page-content .brands-list-grid .brands-list-grid-item {
        width: calc((100% - 2 * 16px) / 3);
    }

    .contacts-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(4n)) {
        margin-right: 0;
    }

    .contacts-page-content .brands-list-grid .brands-list-grid-item:not(:nth-child(3n)) {
        margin-right: 16px;
    }
}