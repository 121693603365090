/*footer*/
footer{
    padding: 138px 0 30px;
    min-height: 554px;
    height: 100%;
    align-items: start !important;
    background: var(--dark-blue);
    background-image: url('../../assets/footer.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
footer .footer-nav-list {
    gap: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-content: space-between;
    flex-wrap: wrap;
}

footer div .footer-nav-list-item{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-transform: uppercase;
    text-align: center;
    color: var(--white);
    padding-bottom: 5px;
}
footer div .footer-nav-list-item:hover,
footer div .footer-nav-list-item:active,
footer div .footer-nav-list-item:focus
{
    color: var(--white);
    border-bottom: solid 1px var(--white);
}

.footer-frame-input {
    margin: 54px auto 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 443px;
}
.footer-form{
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 443px;
}
form{
    width: 100%;
}
.footer-input {
    flex: 1;
    height: 44px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(255, 255, 255, 0.699999988079071);
    border-style: solid;
    border-width: 1px;
}
.footer-text {
    width: 100%;
    color: var(--white);
    opacity: 0.90;
    position: absolute;
    text-transform: uppercase;
    text-align: left;
    line-height: 25px;
    background: transparent;
    border: none;
    height: 44px;
    padding-left: 15px;
    max-width: 443px;
}
.footer-text::placeholder{
    color: #FFFFFF;
    opacity: 0.90;
}
.footer-button {
    width: 125px;
    height: 44px;
    display: flex;
    padding: 20px 48px;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    background-color: var(--white);
    color: var(--dark-grey);
}
.footer-button:hover{
    background-color: var(--cream);
    color: var(--dark-grey);
}
.footer-button:active{
    background-color: var(--cream);
    border: 1px solid #000000;
}
.footer-frame-address {
    display: flex;
    padding: 56px 0;
    justify-content: space-between;
}
.footer-text-left {
    color: var(--white);
    height: auto;
    width: 395px;
    opacity: 0.90;
    font-size: 16px;
    font-weight: 400;
    line-height: 29.28px;
    letter-spacing: 0.1em;
    text-align: left;
}
.footer-text-center {
    color: var(--white);
    height: auto;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.90;
    font-size: 16px;
    font-weight: 400;
    line-height: 29.28px;
    letter-spacing: 0.1em;
    text-align: center;
}
.footer-text-right {
    color: var(--white);
    height: auto;
    opacity: 0.90;
    font-size: 16px;
    font-weight: 400;
    line-height: 29.28px;
    letter-spacing: 0.1em;
    text-align: right;
}

.footer-bottom {
    height: 57px;
    display: flex;
    justify-content: space-between;
}
.footer-center{
    justify-content: center;
}
.footer-bottom div p {
    font-size: 16px;
    font-weight: 400;
    color: var(--white);
}
.footer-bottom .bottom-grid{
    display: flex;
    gap: 16px
}
.footer-bottom-text-left{
    opacity: 0.9;
    line-height: 29.28px;
    letter-spacing: 0.1em;
    text-align: center;
}
.footer-bottom-text-center,.footer-bottom-text-right{
    opacity: 0.7;
    line-height: 29.28px;
    letter-spacing: 0.1em;
}
footer a{
    color: var(--white);
}
footer a:hover{
    /*color: rgba(40, 40, 40, 0.7);*/
    opacity: 1;
    border-bottom: solid 1px;
}
footer a:active,footer a:focus{
    color: var(--white);
}
/*MOBILE*/
@media screen and (max-width: 991px) {

    footer{
        padding: 32px 0 34px;
        min-height: 750px;
        background: var(--dark-blue);
    }
    .footer-bottom .bottom-grid{
        gap: 5px;
        flex-direction: column;
    }
    .footer-frame-address{
        padding: 0 0 55px;
        flex-direction: column;
    }
    .footer-text-right,.footer-text-center,.footer-text-left{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin: 0 ;
        text-align: left;
    }
    .footer-text-right,.footer-text-center,.footer-text-left p{
        opacity: 0.7;
    }
    .footer-text-left{
        margin: 10px 0 16px;
    }
    .footer-social img{
        opacity: 0.7;
        margin-right: 20px;
    }
    .mob-title-text{
        color: var(--white);
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin: 28px 0 10px;
        letter-spacing: 0.1em;
    }
    .footer-frame-input{
        margin: 0 auto 24px;
    }
    .footer-form{
        margin: auto;
    }
    .footer-bottom{
        flex-direction: column;
    }
    .footer-bottom .footer-bottom-text-right, .footer-bottom .footer-bottom-text-left{
        opacity: 0.7;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
    .footer-bottom-text-left{
        margin: 10px 0 0 0;
    }
}

@media (max-width: 600px) {
    footer .footer-nav-list {
        gap: 10px;
        justify-content: left;
        flex-wrap: wrap; /* При ширині менше 500px, розмістити елементи у кілька рядків */
    }

    .footer-nav-card {
        width: 272px;
        flex: 0 0 25%; /* Зробити кожен елемент шириною 33.33%, щоб було 3 у рядку */
    }
}

