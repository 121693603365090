.breadcrum-flex ul{
    display: flex;
    gap: 15px;
    list-style-type: none;
    padding: 15px 0;
}

ul.breadcrumb-items li:before {
    content: "·";
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba(146, 146, 146, 1);
    margin-right: 5px;
}


ul.breadcrumb-items li:first-child:before {
    content: none;
}

.breadcrum-flex .text-gray{
    font-family: Lato;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(40, 40, 40, 0.7);

}
.breadcrum-flex .text-black{
    font-family: Lato;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);

}