/* common */
.service-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    width: 100%;
}

.service-list .service-card {
    height: 297px;
    width: calc((100% - 3 * 32px) / 4);
    max-width: 298px;
    margin-right: 32px;
    margin-bottom: 60px;
    position: relative;
    cursor: pointer;
}
.service-list .service-card:hover {
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.11);
}

.service-list .service-card:nth-child(4n) {
    margin-right: 0;
}

.service-list .service-card .service-card-caption,
.services-section-mobile .service-card .service-card-caption {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 46px;
    background: rgba(0, 0, 0, 0.30);
    color: var(--white);
    font-size: 20px;
    font-weight: 400;
}

/*mobile*/
.services-section-mobile {
    margin-top: 20px;
    padding-bottom: 50px;
    overflow: hidden;
}
.services-section-mobile .service-card {
    height: 344px;
    width: 344px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
}

.services-section-mobile .slider-navigation{
    bottom: -40px;
    width: 32px;
    height: 32px;
}

.services-section-mobile .slider-navigation.nav:first-of-type {
    left: calc(50% - 64px);
    transform: translateX(-50%);
}
.services-section-mobile .slider-navigation.nav:last-of-type {
    right: calc(50% - 64px);
    transform: rotate(180deg) translateX(-50%);
}

.service-filter {
    display: flex;
    align-items: center;
}

.service-filter .filter-button {
    height: 36px;
    border: 1px solid var(--dark-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    cursor: pointer;

    color: var(--dark-grey);
    white-space: nowrap;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
}

.service-filter .filter-button:hover,
.service-filter .filter-button:focus
{
    border: 1px solid rgba(40, 40, 40, 0.7);
    color: rgba(40, 40, 40, 0.7);
}
.service-filter .filter-button.active {
    background-color: var(--dark-grey);
    color: var(--white);
}

.service-filter .filter-button:not(:last-child) {
    margin-right: 12px;
}

/* main page service section*/
.main-services-section .service-section-title-block .service-filter {
    max-width: 868px;
}

.main-services-section {
    padding: 69px 0 72px;
}

.main-services-section .service-section-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-services-section .icon-button .prev,
.main-services-section .icon-button .next {
    width: 32px;
    height: 32px;
}

.main-services-section .icon-button .prev {
    margin-right: 24px;
}

/* all services page */
.all-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 69px 0 63px;

}
.all-services .service-filter {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1120px;
}

.all-services .service-filter .filter-button {
    margin-bottom: 16px;
}
.all-services .service-list {
    width: 100%;
    justify-content: center;
}
.service-list .service-card:last-child {
    margin-right: 0 !important;
}

@media screen and (max-width: 1320px) {
    .service-list {
        justify-content: center;
    }

    .service-list .service-card {
        width: calc((100% - 2 * 32px) / 3);
        margin-bottom: 32px;
    }

    .service-list .service-card:nth-child(4n) {
        margin-right: 32px;
    }

    .service-list .service-card:nth-child(3n) {
        margin-right: 0;
    }
}

@media screen and (max-width: 991px) {
    .service-list {
        margin-top: 20px;
    }

    .service-list .service-card {
        width: calc((100% - 32px) / 2);
    }

    .service-list .service-card:nth-child(3n) {
        margin-right: 32px;
    }

    .service-list .service-card:nth-child(2n) {
        margin-right: 0;
    }

    /* main page service section < 991 */
    .main-services-section {
        padding: 20px 0;
    }

    .main-services-section .service-section-title-block {
        flex-direction: column;
        align-items: flex-start;
    }

    .main-services-section .service-section-title-block .service-filter {
        max-width: 100vw;
        padding: 0 40px;
    }

    /* all services page */
    .all-services {
        padding: 42px 0 27px;
        align-items: flex-start;
    }

    .all-services .service-filter {
        flex-wrap: nowrap;
        justify-content: flex-start;
        max-width: 100vw;
        padding:  0 15px;
    }

    .all-services .book-visit-btn {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .service-card {
        min-width: 344px;
        width: 100% !important;
        background-size: cover;
        margin-right: 0 !important;
    }

    /* main page service section < 600 */
    .main-services-section .service-section-title-block .service-filter {
        max-width: 100vw;
        padding: 0 15px;
    }
}