.header-inner {
    background: var(--white);
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    padding: 25px 0 12px;
}

.header-inner.simplify {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 20px 36px;
}

.header-inner.simplify .content {
    flex: 1;
}

.header-inner.simplify > .hide-content {
    display: none;
}

.header-inner > .hide-content {
    padding-bottom: 23px;
}

header .navigation-list-item,
header .language-selector-item,
header .selected-language {
    color: var(--dark-grey);
    text-align: center;
    display: flex;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    cursor: pointer;
    height: 25px;
}
header .navigation-list-item:hover,
header .navigation-list-item:active,
header .navigation-list-item:focus
{
    border-bottom: solid 1px var(--dark-grey);
}

header .logo img {
    width: 100%;
    object-fit: contain;
}

header .header-inner .language-selector-context-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 24px;
    left: 0;
    z-index: 1;
}

header .language-selector {
    line-height: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;

}

header .top-line .language-selector {
    position: absolute;
    top: 0;
    left: 0;
}

header .language-selector .close-icon {
    margin-left: 8px;
}

header .selected-language-context-opened {
    text-decoration: underline;
}

header .navigation-list {
    gap: 32px;
    padding: 0 47px;
}


header .header-inner > .hide-content {
    position: relative;
}

@media screen and (max-width: 1320px) {
    header .top-line .language-selector {
        left: 40px;
    }

}

@media screen and (max-width: 991px) {
    .header-inner.simplify {
        padding: 0;
    }

    .header-inner.simplify > .hide-content {
        display: flex;
    }

    .header-inner > .hide-content {
        padding-bottom: 0;
    }

    header .top-line .language-selector {
        top: -4px;
        left: 16px;
    }

    .header-inner {
        height: var(--header-height-mobile);
    }

    header .hamburger {
        position: absolute;
        top: 25px;
        right: 24px;
    }

    header .navigation-list-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        position: fixed;
        top: calc(var(--header-height-mobile) - 1px);
        background: #FFFFFF;
        height: calc(100vh - var(--header-height-mobile) + 1px);
        width: 100vw;
        border-top: 1px solid rgba(0, 0, 0, 0.11);
        z-index: 100;
        left: 0;
    }

    header .navigation-list-mobile .navigation-list-item {
        margin-bottom: 36px;
        justify-content: center;
    }

    header .navigation-list-mobile .language-selector {
        margin-top: 50px;
    }

    header .navigation-list-mobile .language-selector {
        position: relative;
        left: 0;

    }

    header .logo {
        max-width: 190px;
    }
}

@media screen and (max-width: 600px) {

}

