.promo-slider .promo-slider-container {
    position: relative;
    overflow: hidden;
    padding: 0 0 60px 0;
}

.promo-slider-container .slider-navigation {
    width: 94px;
    height: 94px;
    background-color: rgba(255, 255, 255, .3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.promo-slider-container .react-slideshow-container .nav:first-of-type {
    left: 30px;
}

.promo-slider-container .react-slideshow-container .nav:last-of-type {
    right: 30px;
    transform: rotate(180deg);
}

.promo-slider-container .react-slideshow-container + ul.indicators {
    position: relative;
    margin-top: 0;
    transform: translateY(-54px);
}

.promo-slider-container .indicators .indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #FFFFFF;
    cursor: pointer;
}

.promo-slider-container .indicators .indicator.active {
    background-color: #000000;
    border: 4px solid #FFFFFF;
}

.promo-slider-container .indicators .indicator:not(:last-child) {
    margin-right: 30px;
}

.promo-slider-content {
    background-size: cover;
    background-position: center;
    position: relative;
    min-height: 718px;
}

.promo-slider-content .learn-more-btn {
    position: absolute;
    right: 178px;
    bottom: 166px;
    padding: 0 28px;
}

@media screen and (max-width: 1320px) {

    .promo-slider-content .learn-more-btn {
        position: absolute;
        right: 80px;
        bottom: 166px;
    }
}

@media screen and (max-width: 991px) {
    .promo-slider .promo-slider-container {
        padding: 0;
    }

    .promo-slider-container .slider-navigation.nav {
        display: none;
    }

    .promo-slider-content {
        min-height: 500px;
    }


    .promo-slider-content .learn-more-btn {
        bottom: 62px;
    }
}

@media screen and (max-width: 600px) {
    .promo-slider-content {
        min-height: 249px;
    }

    .promo-slider-container .react-slideshow-container + ul.indicators {
        transform: translateY(-20px);
    }

    .promo-slider-container .indicators .indicator {
        width: 8px;
        height: 8px;
    }

    .promo-slider-container .indicators .indicator.active {
        border: 2px solid #FFFFFF;
    }

    .promo-slider-container .indicators .indicator:not(:last-child) {
        margin-right: 12px;
    }


    .promo-slider-content .learn-more-btn {
        bottom: 27px;
        right: unset;
        left: 50%;
        transform: translateX(-50%);
        height: 25px;
        width: auto;
        font-size: 16px;
        white-space: nowrap;
        padding: 0 28px;
    }

}
@media screen and (min-width: 1900px) {
    .promo-slider-content {
        min-height: 820px;
    }

}
@media screen and (min-width: 2500px) {
    .promo-slider-content {
        min-height: 996px;
    }

}