
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');


.service-wraper{
    display: flex;
}

.left-column {
    min-width: 720px;
    background: var(--cream);
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.left-column .service-item-title{
    font-family: Lustria;
    font-size: 42px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.5px;
    text-align: left;
    max-width: 568px;
    margin-bottom: 24px;
}
.left-column .service-item-des{
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    max-width: 568px;
    color: var(--dark-grey);
    font-family: Lato Light;
}

.right-column {
    /*flex: 1;*/
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.right-column img {
    max-width: 100%;
    height: auto;
}
.service-procedures-offered{
    padding: 100px 0;
}
.procedures-title{
    font-family: Lora;
    font-size: 30px;
    font-weight: 500;
    line-height: 46px;
    text-align: left;
}
.procedures-list-grid{
    display: flex;
    justify-content: space-between;
    padding: 30px 0 122px 0;
}
.procedures-list-grid-item{
    max-width: 587px;
}
.procedures-list-grid-item h5{
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
}
.procedures-list-grid-item p{
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
    margin-bottom: 19px;
    opacity: 0.7;
}
@media screen and (max-width: 991px) {
    .service-wraper{
        display: flex;
        flex-direction: column-reverse;
    }
    .left-column{
        padding: 30px 22px;
    }
    .left-column .service-item-title{
        font-size: 35px;
        font-weight: 400;
        line-height: 46px;
        text-align: left;
        margin-bottom: 10px;
    }
    .left-column .service-item-des{
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
    }
    .right-column img {
        max-width: 600px;
        height: auto;
    }
    .service-procedures-offered{
        padding: 36px 0;
    }
    .procedures-title{
        font-family: Lustria;
        font-size: 28px;
        font-weight: 400;
        line-height: 46px;
        text-align: left;
    }
    .procedures-list-grid{
        flex-direction: column;
        padding: 24px 0 36px 0;
    }
    .procedures-list-grid-item h5{
        font-size: 14px;
        font-weight: 700;
    }
    .procedures-list-grid-item p{
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
    }
}
@media screen and (max-width: 600px) {
    .right-column img {
        max-width: 100%;
        height: auto;
        min-height: 375px;
    }
    .left-column{
        min-width: 100%;
    }
}